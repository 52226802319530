<template>
  <main class="page_bg_grey blank-aside-js pb-20" :class="(isOpenAside == 'true') ? 'blank-aside' : ''">
    <p class="main-title weight-700">{{ $t("Sales report") }}</p>
    <div class="filter-part mt-5 weight-700">
      <h5 class="weight-700 mb-10">{{ $t("General filtering") }}</h5>
      <div class="mb-10">
        <label for="">
          {{ $t("Date") }}
        </label>
        <date-range-picker ref="picker" :locale-data="dateRangeLocal" :timePicker="true" :timePicker24Hour="true"
          :showWeekNumbers="false" :showDropdowns="true" :autoApply="false" v-model="filter.dateRange"
          :linkedCalendars="true" class="cus-daterange-picker">
          <template v-slot:input="picker" style="min-width: 350px;">
            {{ picker.startDate | formatDateTime }} - {{ picker.endDate | formatDateTime }}
          </template>
          <template #ranges="ranges">
            <div class="ranges">
              <ul>
                <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)"
                  v-if="name != 'This year'">
                  <b>{{ $t(name) }}</b>
                </li>
              </ul>
            </div>
          </template>
        </date-range-picker>
      </div>

      <v-row>
        <v-col :md="4">
          <label for="">
            {{ $t("Supplier ID") }}
          </label>
          <vue-select :options="suppliersOptionsList" :reduce="suppliersOption => suppliersOption.id" label="name"
            class="input cus-select no-uppercase-select" v-model="filter.supplier_id">
          </vue-select>
        </v-col>
        <v-col :md="4">
          <label for="">
            {{ $t("Mobile Phone") }}
          </label>
          <input class="input tour-person__input" type="text" v-model="filter.phone" />
        </v-col>
        <v-col :md="4">
          <label for="">
            {{ $t("Number of transaction") }}
          </label>
          <input class="input tour-person__input" type="text" v-model="filter.id" />
        </v-col>
        <v-col :md="4">
          <label for="">
            {{ $t("Form of payment") }}
          </label>
          <vue-select :options="filter.payment_type.options" class="input cus-select no-uppercase-select"
            v-model="filter.payment_type.selected">
          </vue-select>
        </v-col>
        <v-col :md="4">
          <label for="">
            {{ $t("Business name") }}
          </label>
          <input type="text" class="input" v-model="filter.business_name">
        </v-col>
        <v-col :md="4">
          <label for="">
            {{ $t("Business ID") }}
          </label>
          <input type="text" class="input" v-model="filter.business_id">
        </v-col>
      </v-row>
    </div>


    <button class="primary-btn search_btn" @click="filterReport(1)">{{ $t("Search") }}</button>

    <div class="table-content-part">
      <div class="d-flex justify-content-between flex-wrap-reverse">
        <div class="search-bar">
          <p class="search-label">{{ $t("Search") }}</p>
          <v-text-field v-model="search" single-line hide-details class="search-form"
            @input="debounceSearch"></v-text-field>
        </div>
        <div class="total-value mb-10">
          <div>
            <span class="px-4">{{ $t("Consumer price") }}: </span> {{ reportData.total_price | formatPrice }}
          </div>
          <div>
            <span class="px-4">{{ $t("Cost Price") }}: </span> {{ reportData.total_business_price | formatPrice }}
          </div>
        </div>
      </div>
      <v-data-table :headers="headers" :search="search" :items="reportData.data"
        class="cus-table editable-table sales-table striped-table" :single-expand="false" :expanded.sync="expanded"
        item-key="id" :loading="loading" :options.sync="options" :server-items-length="reportData.total" :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }">
        <template v-slot:[`item.transaction_id`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ item.transaction_id }}
          </div>
          <div v-else>
            {{ item.transaction_id }}
          </div>
        </template>
        <template v-slot:[`item.business_name`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ item.business_name }}
          </div>
          <div v-else>
            {{ item.business_name }}
          </div>
        </template>
        <template v-slot:[`item.phone_number`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ item.phone_number }}
          </div>
          <div v-else>
            {{ item.phone_number }}
          </div>
        </template>
        <template v-slot:[`item.supplier_name`]="{ item }">
          <!-- <div class="ticket-item" :style="{'background-color': item.supplier_logo_background_color, 'color': item.supplier_font_color}"> -->
          <div>
            {{ item.supplier_name }}
          </div>
        </template>
        <template v-slot:[`item.product_name`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            <div v-if="item.supplier_id == suppliers.tourDeals">
              {{ item.dealTitle ? item.dealTitle : item.hotelName }}
            </div>
            <div v-else>
              {{ item['product_name_' + currentLang] }}
            </div>
          </div>
          <div v-else>
            <div v-if="item.supplier_id == suppliers.tourDeals">
              {{ item.dealTitle ? item.dealTitle : item.hotelName }}
            </div>
            <div v-else>
              {{ item['product_name_' + currentLang] }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ getDateFromString(item.transaction_end_timestamp) }}
          </div>
          <div v-else>
            {{ getDateFromString(item.transaction_end_timestamp) }}
          </div>
        </template>
        <template v-slot:[`item.hour`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ getTimeFromString(item.transaction_end_timestamp) }}
          </div>
          <div v-else>
            {{ getTimeFromString(item.transaction_end_timestamp) }}
          </div>
        </template>
        <template v-slot:[`item.price`]="{ item }">
           <div v-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ item.price | formatPrice }}
          </div>
          <div v-else>
            {{ item.price | formatPrice }}
          </div>
        </template>
        <template v-slot:[`item.business_price`]="{ item }">
          <div v-if="item.parent_transaction_id != null && item.price < 0 && item.payment_cancel_status == 'AWAITING'" class="color-red4">
          </div>
          <div v-else-if="item.parent_transaction_id != null && item.price < 0" class="color-red4">
            {{ item.business_price | formatPrice }}
          </div>
          <div v-else-if="item.payment_cancel_status != 'AWAITING'">
            {{ item.business_price | formatPrice }}
          </div>
          <div v-else-if="item.supplier_id == suppliers.we4G">
            {{ item.business_price | formatPrice }}
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <template v-if="item.parent_transaction_id != null && item.price < 0">
            <div v-if="item.is_partly" class="ticket-item bg-red8">
              {{ $t("Partial cancel") }}
            </div>
            <div v-else-if="item.payment_cancel_status == 'AWAITING'" class="ticket-item blue darken-2 white--text">
              {{ $t("Pending") }}
            </div>
            <div v-else class="ticket-item bg-grey4 color-red4">
              <!-- {{ $t("Cancelled") }} -->
              {{ $t(item.payment_cancel_status) }}
            </div>
          </template>
          <div class="ticket-item bg-blue" v-else-if="item.status == 5">
            {{ $t("Pending") }}
          </div>
          <div class="ticket-item bg-green1" v-else-if="item.status == 3">
            {{ $t("Success") }}
          </div>
          <div class="ticket-item bg-red" v-else-if="item.status == 4">
            {{ $t("Failed") }}
          </div>
          <!-- <div class="ticket-item bg-2" v-else-if="item.status == 1">
            {{ $t("Opened") }}
          </div> -->
          <div class="ticket-item bg-1" v-else-if="item.status == 6">
            {{ $t("Cancelled") }}
          </div>
        </template>
        <template v-slot:[`item.payment_cancel_status`]="{ item }">
          <template v-if="item.parent_transaction_id == null && item.price >= 0">
            <div v-if="item.payment_cancel_status == 'NO'">
              <div class="ticket-item bg-blue3 pointer"
                v-if="[suppliers.ravkav, suppliers.ramiLevi, suppliers.partnerId, suppliers.mobile012Id].includes(item.supplier_id) && item.status == 3 && item.is_cancelable == true && item.is_canceled == false && item.supplier_id != null && item.price > 0"
                @click="openCancellationModal(item, true)">
                {{ $t("Cancel action") }}
              </div>
              <div class="ticket-item bg-blue3 pointer"
                v-else-if="isCancelPermission && item.status == 3 && item.is_cancelable == true && item.is_canceled == false && item.supplier_id != null && item.price > 0"
                @click="openCancellationModal(item)">
                {{ $t("Cancel action") }}
              </div>
              <div class="ticket-item bg-blue3 pointer"
                v-else-if="item.supplier_id == suppliers.we4G && diffHours(item.created_at) < 3 && item.status == 3"
                @click="openCancellationModal(item, true)">
                {{ $t("Cancel action") }}
              </div>
              <!-- <div class="ticket-item bg-blue3 pointer"
                v-else-if="item.supplier_id == suppliers.we4G && diffHours(item.created_at) < 3"
                @click="openCancellationModal(item, true)">
                {{ $t("Cancel action") }}
              </div> -->
              <div v-else>
                {{ $t("Not cancelled") }}
              </div>
            </div>
            <div class="ticket-item bg-yellow" v-else-if="item.payment_cancel_status == 'AWAITING'">
              {{ $t("Waiting") }}
            </div>
            <div class="ticket-item bg-red6" v-else-if="item.payment_cancel_status == 'DONE'">
              {{ $t("Cancelled") }}
            </div>
            <div class="ticket-item bg-grey" v-else-if="item.payment_cancel_status == 'REJECT'">
              {{ $t("Rejected") }}
            </div>
          </template>
          <template v-if="item.parent_transaction_id != null && item.price < 0">
            <div class="ticket-item bg-grey3 color-primary">
              {{ item.parent_transaction_id }}
            </div>
          </template>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <img src="/assets/img/print.png" alt="" class="print-img hover-pointer" @click="rowPrint(item)">
        </template>
        <template v-slot:[`item.expand`]="{ item }">
          <div
            v-if="item.supplier_type != suppliersTypes.prepaid || (item.parent_transaction_id != null && item.price < 0) || item.supplier_id == 127">
            <v-btn small @click="expanded = [item]" v-if="!expanded.includes(item)">{{ $t('More') }}</v-btn>
            <v-btn small @click="expanded = []" v-if="expanded.includes(item)">{{ $t('Less') }}</v-btn>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <!-- <td :colspan="headers.length" class="color-white" :style="{'background-color': item.supplier_logo_background_color, 'color': item.supplier_font_color}"> -->
          <td :colspan="headers.length" :style="{ 'background-color': item.supplier_logo_background_color }">
            <div v-if="item.parent_transaction_id != null && item.price < 0" class="expand-part">
              <div class="text-center">
                <p>{{ $t("Canceled Price") }}</p>
                <p>{{ Math.abs(item.price) || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Sale Transaction Price") }}</p>
                <p>{{ item.parent_transaction_price || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Cancellation Note") }}</p>
                <p>{{ item.cancellation_note || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Cancel confirmation number") }}</p>
                <p>{{ item.cancel_confirmation_number || '-' }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_type == suppliersTypes.tourism" class="expand-part">
              <div class="text-center">
                <p>{{ $t("Number of transaction") }}</p>
                <p>{{ item.id }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("External Order ID") }}</p>
                <p>{{ item.external_code || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Transaction type") }}</p>
                <p>{{ item.dealType || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Customer name") }}</p>
                <p>{{ Array.isArray(item.persons) ? [].concat.apply([], item.persons).map(person => person.is_customer ==
                  1 ? person.firstName + ' ' + person.lastName : null).filter(customer => customer != null).join(' | ') :
                  item.customer_name }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("To") }}</p>
                <p>{{ item['destinationName_' + currentLang] }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Hotel") }}</p>
                <p>{{ item.hotelName || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Stars") }}</p>
                <p>{{ item.hotelRating || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Hosting") }}</p>
                <p>{{ item.farebasis ? item.farebasis.join(' | ') : '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Number of passengers") }}</p>
                <p>{{ item.paxcomplect ? item.paxcomplect.join('').length : '-' }}</p>
              </div>
              <router-link
                :to="{ path: '/report-details/' + item.transaction_id, query: { phoneNumber: item.phone_number } }">
                <button class="full-detail_btn">{{ $t("For full details") }}</button>
              </router-link>
            </div>
            <div v-else-if="item.supplier_type == suppliersTypes.gift_cards" class="expand-part">
              <div class="text-center">
                <p>{{ $t("Number of transaction") }}</p>
                <p>{{ item.id }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("External Order ID") }}</p>
                <p>{{ item.external_transaction_id || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Gift Card Code") }}</p>
                <p>{{ item.external_code || '-' }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_type == suppliersTypes.games" class="expand-part">
              <div class="text-center">
                <p>{{ $t("Number of transaction") }}</p>
                <p>{{ item.id }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("External Order ID") }}</p>
                <p>{{ item.external_transaction_id || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ item.supplier_id == suppliers.xboxOneId ? $t("Game Card Code") : $t("Game Card Code") }}</p>
                <p>{{ item.external_code || '-' }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Serial Number") }}</p>
                <p>{{ item.pin_code || '-' }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_id == suppliers.iecMatamId" class="expand-part">
              <div class="text-center">
                <p>{{ $t("Contract number") }}</p>
                <p>{{ item.contract_number }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_id == suppliers.iecPayment" class="expand-part">
              <div class="text-center">
                <p>{{ $t("Address") }}</p>
                <p>{{ item.client_address }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Purchase amount") }}</p>
                <p>{{ item.external_price }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Approval number") }}</p>
                <p>{{ item.external_transaction_id }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Loading code") }}</p>
                <p class="direction-ltr">{{ item.external_code }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_id == suppliers.highway6Id" class="expand-part">
              <div class="text-center">
                <p>{{ $t("ID number") }}</p>
                <p>{{ item.customer_id }}</p>
              </div>
              <div class="text-center">
                <p>{{ $t("Bill number") }}</p>
                <p>{{ item.bill_number }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_id == suppliers.ravkav" class="expand-part"
              :class="{ 'expand-part': (item.payment_cancel_status === 'NO'), 'expand-part-red': !(item.payment_cancel_status === 'NO') }">
              <div class="text_center">
                <p>{{ $t("Number of transaction") }}</p>
                <p>{{ item.id }}</p>
              </div>
              <div class="text_center">
                <p>{{ $t("Ticket Number") }}</p>
                <p>{{ item.card_number }}</p>
              </div>
              <div class="text_center">
                <p>{{ $t("Multi-line transaction number") }}</p>
                <p>{{ item.confirmation }}</p>
              </div>
              <div v-if="item.contract_number" class="text_center">
                <p>{{ $t("Contract name") }}</p>
                <p>{{ item.contract_number.split(",")[0] }}</p>
              </div>
              <div v-if="item.contract_number" class="text_center">
                <p>{{ $t("Contract Type") }}</p>
                <p>{{ item.contract_number.split(",")[1] }}</p>
              </div>
            </div>
            <div v-else-if="item.supplier_id == 127" class="expand-part">
              <div class="text-center">
                <p>{{ $t("Order ID") }}</p>
                <p>{{ item.deal_id }}</p>
              </div>
            </div>
            <div v-else class="expand-part">
              <!-- <button class="full-detail_btn">{{ $t("For full details") }}</button> -->
            </div>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="table-print-excel-part d-flex align-items-center flex-wrap">
      <div class="d-flex align-items-center" @click="printTableData">
        <img src="/assets/img/print.png" alt="" class="print-img hover-pointer mx-7">
        <label for="">{{ $t("For printing") }}</label>
      </div>
      <div class="d-flex align-items-center" @click="exportSalesReportHandler">
        <img src="/assets/img/excel.png" alt="" class="excel-img hover-pointer">
        <label for="">{{ $t("Export to Excel") }}</label>
      </div>
    </div>
    <loading :active.sync="showLoadingAnimation" :can-cancel="false" :is-full-page="true"></loading>
    <CancellationModal :cancellationItem="cancellationItem" @refilter="filterReport(1)" :isRavkavModal="isRavkavModal"
      :isCancelPermission="isCancelPermission"></CancellationModal>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import CancellationModal from '../../components/report/CancellationModal.vue'

import { saveAs } from 'file-saver';
export default {
  name: "SalesReport",
  components: {
    CancellationModal
  },
  computed: {
    ...mapState({
      loading: state => state.report.loading,
      exportInProgress: state => state.report.exportInProgress,
      reportData: state => state.report.salesReportData_receipts,
      printData: state => state.report.salesPrintData_receipts,
      supplier_items: state => state.supplier.supplier_items,
      contracts: state => state.report.salesDetails,
    }),
    ...mapGetters('permission', {
      hasApiAccess: 'hasApiAccess'
    }),
    showLoadingAnimation() {
      return this.loading || this.exportInProgress;
    },
    suppliersOptionsList() {
      let suppliersOptionsList = [];

      if (this.supplier_items) {
        for (let supplierType in this.supplier_items) {
          let supplierTypeItems = this.supplier_items[supplierType];
          for (let supplerData of supplierTypeItems) {
            if (!suppliersOptionsList.filter(e => e.id === supplerData.id).length > 0) {
              suppliersOptionsList.push({ id: supplerData.id, name: supplerData.name });
            }
          }
        }
      }
      if (suppliersOptionsList) {
        suppliersOptionsList.sort(function (a, b) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      }

      suppliersOptionsList.unshift({ id: null, name: 'All' });

      return suppliersOptionsList;
    },
    statuses() {
      return {
        1: this.$t("Opened"),
        2: '2',
        3: this.$t("Success"),
        4: this.$t("Failed"),
        5: this.$t("Pending"),
        6: this.$t("Cancelled")
      };
    },
    fieldsToExport() {
      return [
        {
          name: this.$t("Business name"),
          key: "business_name",
          field: "business_name",
        },
        {
          name: this.$t("Mobile"),
          key: "phone_number",
          field: "phone_number",
        },
        {
          name: this.$t("Supplier ID"),
          key: "supplier_name",
          field: "supplier_name",
        },
        {
          name: this.$t("Product"),
          key: "product_name_" + this.currentLang,
          field: "product_name_" + this.currentLang
        },
        {
          name: this.$t("Date"),
          key: "transaction_date",
          field: "transaction_end_timestamp",
          dateFormat: "YYYY-MM-DD"
        },
        {
          name: this.$t("Hour"),
          key: "transaction_time",
          field: "transaction_end_timestamp",
          dateFormat: "HH:mm:ss"
        },
        {
          name: this.$t("Consumer price"),
          key: "price",
          field: "price"
        },
        {
          name: this.$t("Cost Price"),
          key: "business_price",
          field: "business_price"
        },
        {
          name: this.$t("Status"),
          key: "status",
          field: "status",
          options: this.statuses
        },
        {
          name: this.$t("Request for cancellation"),
          key: "payment_cancel_status",
          field: "payment_cancel_status"
        }
      ];
    },
    isCancelPermission() {
      return this.hasApiAccess('/payment/cancel', 'POST');
    }
  },
  data() {
    return {
      search: "",
      visible: false,
      headers: [
        {
          text: this.$t("Transaction number"),
          align: "center",
          sortable: true,
          value: "transaction_id"
        },
        {
          text: this.$t("Business ID"),
          align: "center",
          sortable: true,
          value: "business_id"
        },
        {
          text: this.$t("Business name"),
          align: "center",
          sortable: true,
          value: "business_name"
        },
        {
          text: this.$t("Mobile"),
          align: "center",
          sortable: true,
          value: "phone_number"
        },
        {
          text: this.$t("Supplier ID"),
          align: "center",
          sortable: true,
          value: "supplier_name"
        },
        {
          text: this.$t("Product"),
          align: "center",
          sortable: true,
          value: "product_name"
        },
        {
          text: this.$t("Date"),
          align: "center",
          sortable: true,
          value: "date"
        },
        {
          text: this.$t("Hour"),
          align: "center",
          sortable: true,
          value: "hour"
        },
        {
          text: this.$t("Consumer price"),
          align: "center",
          sortable: true,
          value: "price"
        },
        {
          text: this.$t("Cost Price"),
          align: "center",
          sortable: true,
          value: "business_price"
        },
        {
          text: this.$t("Status"),
          align: "center",
          sortable: true,
          value: "status"
        },
        {
          text: this.$t("Request for cancellation"),
          align: "center",
          sortable: true,
          value: "payment_cancel_status"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        },
        // { 
        //   text: '', 
        //   value: 'data-table-expand',
        //   align: "center" 
        // },
        {
          text: '',
          value: 'expand',
          align: 'center',
          sortable: false
        }
      ],
      filter: {
        dateRange: {
          startDate: moment().startOf('month'),
          endDate: moment().endOf('day')
        },
        isTimeLimit: false,
        supplier_id: null,
        id: "",
        phone: "",
        payment_type: {
          selected: { label: "All", code: null },
          options: [
            { label: "All", code: null },
            { label: "CC", code: "cc" },
            { label: "Credit", code: "credit" },
            { label: "Cash", code: "cash" }
          ]
        },
        business_name: null,
        business_id: null
      },
      expanded: [],
      suppliers: {
        iecMatamId: 186,
        highway6Id: 187,
        tourDeals: 111,
        amazonId: 1000,
        playStationId: 1001,
        xboxOneId: 1002,
        steamId: 1006,
        googlePlayId: 1009,
        razerGoldId: 1010,
        netflixId: 1011,
        iTunesId: 1012,
        iecPayment: 1013,
        ravkav: 999,
        we4G: 1100,
        ramiLevi: 1101,
        partnerId: 127,
        mobile012Id: 201
      },
      suppliersTypes: {
        prepaid: 1,
        bills: 2,
        manual_cards: 3,
        gift_cards: 4,
        tourism: 5,
        games: 6
      },
      itemsPerPageOptions: [10, 15, 25, 50, 100],
      options: {
        itemsPerPage: 10,
        page: 1
      },
      filtersInUse: {},
      cancellationItem: {},
      isRavkavModal: false
    };
  },
  methods: {
    ...mapActions("report", {
      filter_sales_report: "filter_sales_report",
      filter_sales_report_print: "filter_sales_report_print",
      export_sales_report: "export_sales_report",
      cancel_payment: "cancel_payment"
    }),
    ...mapActions('supplier', {
      getAllSupplierItems: 'getAll'
    }),

    diffHours(time) {
      return moment.duration(moment().diff(time)).asHours();
    },

    debounceSearch() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.filterReport(1);
      }, 1000)
    },

    getDateFromString(dateString) {
      if (dateString) {
        return dateString.substring(0, 10);
      }
    },
    getTimeFromString(dateString) {
      if (dateString) {
        return dateString.substring(11, 19);
      }
    },

    prepareFilters() {
      const filters = {
        start: this.filter.dateRange.startDate,
        end: this.filter.dateRange.endDate,
        payment_type: this.filter.payment_type.selected.code,
        id: this.filter.id,
        supplier_id: this.filter.supplier_id,
        phone: this.filter.phone,
        search: this.search,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0],
        business_name: this.filter.business_name,
        business_id_filter: this.filter.business_id,
      }

      return filters;
    },
    filterReport(page) {
      page = +page || +this.options.page;
      this.options.page = page;
      let itemsPerPage = +this.options.itemsPerPage;
      let skip = (page - 1) * itemsPerPage;
      if (page === 1) {
        this.filtersInUse = this.prepareFilters()
      }
      const filters = this.filtersInUse;
      this.filter_sales_report({ limit: itemsPerPage, skip: skip, filters: filters });
    },

    /* print one row */
    rowPrint(item) {
      item.date = this.getDateFromString(item.transaction_end_timestamp);
      item.hour = this.getTimeFromString(item.transaction_end_timestamp);

      let defaultProperties = [
        { field: 'payment_cancel_status', displayName: this.$t("Request for cancellation") },
        { field: 'status', displayName: this.$t("Status") },
        { field: 'business_price', displayName: this.$t("Cost Price") },
        { field: 'price', displayName: this.$t("Consumer price") },
        { field: 'hour', displayName: this.$t("Hour") },
        { field: 'date', displayName: this.$t("Date") },
        { field: 'product_name_' + this.currentLang, displayName: this.$t("Product") },
        { field: 'supplier_name', displayName: this.$t("Supplier ID") },
        { field: 'phone_number', displayName: this.$t("Mobile") },
        { field: 'business_name', displayName: this.$t("Business name") }
      ]

      let addtionalProperties = [];
      if (item.supplier_type == this.suppliersTypes.tourism) {
        if (!item.external_code) {
          item.external_code = '-';
        }
        if (!item.dealType) {
          item.dealType = '-';
        }
        if (Array.isArray(item.persons)) {
          item.updated_customer_name = [].concat.apply([], item.persons).map(person => person.is_customer == 1 ? person.firstName + ' ' + person.lastName : null).filter(customer => customer != null).join(' | ')
        } else {
          item.updated_customer_name = item.customer_name;
        }
        if (!item.hotelName) {
          item.hotelName = '-';
        }
        if (!item.hotelRating) {
          item.hotelRating = '-';
        }
        if (item.farebasis) {
          item.farebasis = item.farebasis.join(' | ');
        } else {
          item.farebasis = '-';
        }
        if (item.paxcomplect) {
          item.paxcomplect = item.paxcomplect.join('').length;
        } else {
          item.paxcomplect = '-';
        }

        addtionalProperties = [
          { field: 'id', displayName: this.$t("Number of transaction") },
          { field: 'external_code', displayName: this.$t("External Order ID") },
          { field: 'dealType', displayName: this.$t("Transaction type") },
          { field: 'updated_customer_name', displayName: this.$t("Customer name") },
          { field: 'destinationName_' + this.currentLang, displayName: this.$t("To") },
          { field: 'hotelName', displayName: this.$t("Hotel") },
          { field: 'hotelRating', displayName: this.$t("Stars") },
          { field: 'farebasis', displayName: this.$t("Hosting") },
          { field: 'paxcomplect', displayName: this.$t("Number of passengers") }
        ]
      } else if (item.supplier_type == this.suppliersTypes.gift_cards) {
        if (!item.external_transaction_id) {
          item.external_transaction_id = '-';
        }
        if (!item.external_code) {
          item.external_code = '-';
        }
        addtionalProperties = [
          { field: 'id', displayName: this.$t("Number of transaction") },
          { field: 'external_transaction_id', displayName: this.$t("External Order ID") },
          { field: 'external_code', displayName: this.$t("Gift Card Code") }
        ]
      } else if (item.supplier_id == this.suppliers.iecMatamId) {
        addtionalProperties = [
          { field: 'contract_number', displayName: this.$t("Contract number") }
        ]
      } else if (item.supplier_id == this.suppliers.highway6Id) {
        addtionalProperties = [
          { field: 'customer_id', displayName: this.$t("ID number") },
          { field: 'bill_number', displayName: this.$t("Bill number") }
        ]
      }

      let lineProperties = [...defaultProperties, ...addtionalProperties];
      const printData = [];
      printData.push(item);
      printJS({
        printable: printData,
        properties: lineProperties,
        type: 'json'
      })
    },
    /* print table */
    async printTableData() {
      const filters = this.filtersInUse;
      const totalRowsNumber = this.reportData.total;
      this.filter_sales_report_print({ filters: filters, total: totalRowsNumber }).then(
        result => {
          printJS({
            printable: result,
            properties: [
              { field: 'payment_cancel_status', displayName: this.$t("Request for cancellation") },
              { field: 'status', displayName: this.$t("Status") },
              { field: 'business_price', displayName: this.$t("Cost Price") },
              { field: 'price', displayName: this.$t("Consumer price") },
              { field: 'hour', displayName: this.$t("Hour") },
              { field: 'date', displayName: this.$t("Date") },
              { field: 'product_name_' + this.currentLang, displayName: this.$t("Product") },
              { field: 'supplier_name', displayName: this.$t("Supplier ID") },
              { field: 'phone_number', displayName: this.$t("Mobile") },
              { field: 'business_name', displayName: this.$t("Business name") }
            ],
            type: 'json',
            gridHeaderStyle: this.currentLang == 'en' ? 'direction: ltr; border: 1px solid #3971A5;' : 'direction: rtl; border: 1px solid #3971A5;',
            gridStyle: this.currentLang == 'en' ? 'direction: ltr; border: 1px solid #3971A5;' : 'direction: rtl; border: 1px solid #3971A5;'
          })
        },
        error => {
        }
      );
    },

    exportSalesReportHandler(event) {
      if (!this.reportData || !this.reportData.total) {
        return false;
      }
      const filters = this.filtersInUse;
      const fields = this.fieldsToExport;
      this.export_sales_report({ format: 'excel', fields: fields, filters: filters }).then(
        result => {
          saveAs(result, 'sales_report_' + moment().format('YYYY-MM-DD') + '.xlsx');
        },
        error => {
        }
      );
    },
    openCancellationModal(item, isRavkav = false) {
      this.isRavkavModal = isRavkav;
      this.cancellationItem = item;
      this.$modal.show('cancellationModal');
    }
  },
  watch: {
    options: {
      handler() {
        this.filterReport();
      },
      deep: true,
    },
  },
  mounted() {
    this.filter.start_date = new Date();
  },
  created() {
    this.getAllSupplierItems();
  }
};
</script>

<style lang="scss">
.text_center {
  text-align: center;
  float: right;
  padding-left: 100px;
}

.position {
  display: contents;
}
</style>
<style scoped lang="scss">
.direction-ltr {
  direction: ltr !important;
}

.filter-part {
  border: 1px solid #707070;
  margin: 20px 50px 0 50px;
  padding: 10px 15px 30px;
}

.total-value {
  font-weight: 600;
  color: #707070;
  font-size: 22px;

  span {
    font-size: 18px;
    color: #002036;
  }
}

.sales-table {
  .ticket-item {
    border: 1px solid #707070;
    color: #fff;
    border-radius: 5px;
    width: 70px;
    margin: 0 auto;
    text-align: center;

    &.expand-ticket-item {
      width: auto;
      padding: 0 5px;
    }
  }

  .print-img {
    width: 40px;
  }
}

.search_btn {
  margin: 0 auto;
  margin-top: 20px;
}

.expand-part {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 10px;

  div p:before {
    content: ' ';
    display: inline-block;
  }

  p {
    margin: 10px 0;
  }

  .full-detail_btn {
    background-color: #fff;
    color: #000;
    font-weight: 700;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 7px 20px;
  }
}

.expand-part-red {
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  color: red;

  p {
    margin: 10px 0;
  }

  .full-detail_btn {
    background-color: #fff;
    color: #000;
    font-weight: 700;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 7px 20px;
    margin: 10px 0;
  }
}

.rtl-type {
  .excel-img {
    margin-right: 50px;
    margin-left: 15px;
  }
}

@media screen and (max-width: 540px) {
  .table-print-excel-part .excel-img {
    margin-left: 15px;
  }

  .rtl-type {
    .excel-img {
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: 480px) {
  .filter-part {
    margin: 20px 20px 0;
  }

  .search_btn {
    max-width: 80%;
  }
}
</style>